<script lang="ts">
  import { onMount } from "svelte";
  import { clickOutside } from "./click-outside";
  import { showroom, changeShowroom, store } from "./store";
  import { openLegacyModal } from "./open-legacy-modal";
  import Social from "./Social.svelte";

  onMount(() =>
    changeShowroom(Number(document.getElementById("drawer").dataset.showroomId))
  );

  const bookmarkClick = () => {
    const element: any = document.querySelector("#bookmark-count");
    if (element.href.includes("#")) {
      alert("У вас нет закладок");
    } else {
      window.location.href = element.href;
    }
  };

  let opened = false;

  $: opened && document.querySelector("main").classList.add("blur");
  $: !opened && document.querySelector("main").classList.remove("blur");
</script>

<div
  use:clickOutside={{ enabled: opened, cb: () => (opened = false) }}
  class:-translate-x-full={opened === false}
  class:translate-x-0={opened === true}
  class:transform={true}
  class="fixed top-0 h-full p-8 pr-12 transition bg-gray-50 drawer "
>
  <a href="/" class="flex items-center justify-center flex-1">
    <img
      alt="Автопарк 76"
      src="https://autopark76.ru/assets/main/img/tw/logo.png"
      class="w-24 sm:w-32"
    />
  </a>
  <hr class="my-4 border-gray-100" />
  <nav class="flex flex-col pr-2 space-y-4 text-uppercase">
    <a href="/avtomobili">Каталог</a>
    <a href="/pokupka">Хочу продать</a>
    <a href="/kredit">Хочу в кредит</a>
    <a href="/komissia">Комиссия</a>
    <a href="/predprodaznaa-podgotovka">Детейлинг</a>
    <a href="/strahovanie">Страхование</a>
    <a href="/vakansii">Вакансии</a>
    <a href="/otzyvy">Отзывы</a>
    <button type="button" class='text-left text-uppercase' on:click={() => bookmarkClick()}>Закладки</button>
  </nav>
  <hr class="my-4 border-gray-100" />
  <Social />
  <hr class="my-4 border-gray-100" />

  <button
    type="button"
    on:click={() => openLegacyModal($showroom.id)}
    class="flex items-center space-x-2"
  >
    <img
      alt="Адрес"
      width="24px"
      src="/assets/main/img/tw/location.svg"
    />
    <span>{$showroom.address}</span>
  </button>

  <a
    class="flex items-center mt-2 space-x-2"
    data-ym="click-on-phone"
    href={$showroom.phoneHref}
  >
    <img
      alt="Телефон"
      width="24px"
      src="/assets/main/img/tw/phone.svg"
    />
    <span>{$showroom.phoneFull}</span>
  </a>
</div>

<div class="fixed z-50 bottom-4">
  <button
    type="button"
    class="p-3 ml-2 bg-red-600 rounded-full drawer-change"
    on:click={() => (opened = !opened)}
  >
    <img
      alt="Меню"
      width="24px"
      style="transform: translateY(3px)"
      src="/assets/main/img/tw/menu.svg"
    />
  </button>
</div>

<style>
  a,
  a:hover,
  a:visited,
  a:focus {
    text-decoration: none;
    color: inherit;
  }
</style>
