export const clickOutside = (node, { enabled: initialEnabled, cb }) => {
    const handleOutsideClick = ({ target }) => {
        if (!node.classList.contains('blur')) {
            cb();
        }
    };
    function update({ enabled }) {
        if (enabled) {
            setTimeout(() => {
                window.addEventListener("click", handleOutsideClick);
            }, 100);
        }
        else {
            window.removeEventListener("click", handleOutsideClick);
        }
    }
    update({ enabled: initialEnabled });
    return {
        update,
        destroy() {
            window.removeEventListener("click", handleOutsideClick);
        },
    };
};
