<script lang="ts">
  import { showroom } from "./store";
  import { openLegacyModal } from "./open-legacy-modal";
</script>

<div class="flex items-center flex-1 space-x-1 social">
  <a
    data-ym="click-on-telegram"
    class="telegram"
    href="tg://resolve?domain={$showroom.telegram}"
  >
    <img
      alt="telegram"
      width="32px"
      src="/assets/main/img/tw/telegram.svg"
    />
  </a>

  <a
    href="viber://chat?number={$showroom.viber}"
    data-ym="click-on-viber"
  >
    <img
      alt="viber"
      width="32px"
      src="/assets/main/img/tw/viber.svg"
    />
  </a>

  <a
    data-ym="click-on-wa"
    href="https://api.whatsapp.com/send?phone={$showroom.whatsapp}"
  >
    <img
      alt="whatsap"
      width="32px"
      src="/assets/main/img/tw/whatsapp.svg"
    />
  </a>

  <a
    target="_blank"
    href="https://vk.com/{$showroom.vk}"
  >
    <img alt="vk" width="32px" src="/assets/main/img/tw/vk.svg" />
  </a>

  <a
    target="_blank"
    href="https://www.instagram.com/{$showroom.instagram}"
  >
    <img
      alt="instagram"
      width="32px"
      src="/assets/main/img/tw//instagram.svg"
    />
  </a>

  <button
    type="button"
    on:click={() => openLegacyModal($showroom.id)}
  >
    <img
      alt="yandex map"
      width="32px"
      src="/assets/main/img/tw/yandex.svg"
    />
  </button>
</div>

<style lang="postcss">
  a,
  button {
    @apply w-8 h-8;
    @apply flex justify-center items-center;
    @apply bg-white shadow-lg overflow-hidden rounded-full;
    @apply relative;
  }
</style>
