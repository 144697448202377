import { writable, derived } from "svelte/store";
const state = {
    selectedShowroom: 1,
    showrooms: [
        {
            id: 1,
            phoneShort: "900-190",
            phoneFull: "8 (4852) 900-190",
            phoneHref: "tel:+74855900190",
            telegram: "Avtopark76bot",
            viber: "+79201022003",
            whatsapp: "+79201022003",
            vk: "autopark76",
            instagram: "autopark76.ru",
            address: "Менжинского, 1В",
        },
        {
            id: 2,
            phoneShort: "909-000",
            phoneFull: "8 (4852) 909-000",
            phoneHref: "tel:+74852909000",
            telegram: "autopark76bot",
            viber: "+79201455224",
            whatsapp: "+79201455224",
            vk: "autopark76",
            instagram: "autopark76.ru",
            address: "Полушкина Роща, 35",
        },
        {
            id: 3,
            phoneShort: "700-091",
            phoneFull: "8 (4852) 700-091",
            phoneHref: "tel:+74852700-091",
            telegram: "autopark76bot",
            viber: "+79022208876",
            whatsapp: "+79022208876",
            vk: "autopark76",
            instagram: "autopark76.ru",
            address: "Московский проспект, 108А",
        }
    ],
};
export const store = writable(state);
// mutations
export const changeShowroom = (id) => store.update((state) => Object.assign(state, { selectedShowroom: id }));
// getters
export const showroom = derived(store, ($store) => $store.showrooms.find(({ id }) => $store.selectedShowroom === id));
